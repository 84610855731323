<template>
    <div>
        <div class="org_body" v-if="item && item.url">
            <iframe id="VkFrame" type="text/html" :src="item.url" allowfullscreen frameborder="0" />
        </div>
    </div>
</template>
<script>
export default {
    name: "VkFrame",
    props: {
        item: Object
    },
}
</script>
<style scoped>
iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
}

.org_body {
    background: #fff;
    border-radius: 12px;
    padding: 10px;
    text-transform: uppercase;
    margin-bottom: 15px;
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
}
</style>