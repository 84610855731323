<template>
    <div>
        <div class="default_box">
            <div class="default_header">
                <h1>Помощь по сайту</h1>
            </div>
            <div class="default_box_content bg_white p15" v-if="Preloader">
                <div class="help_user_title text-left" v-if="item">
                    <div v-if="item.name" class="pb-3"><b>{{item.name}}</b></div>
                    <div v-if="item.text" v-html="$htmContext(item.text)"></div>
                </div>
                <form @submit.prevent="send">
                    <div v-for="(form_item, index) in data" :key="index">
                        <label :for="form_item.name" class="main_input_label" v-html="form_item.label"></label>
                        <div v-if="form_item.input">
                            <input v-if="form_item.name !== 'telephone'" :id="form_item.name" type="text" class="main_input" v-model="form[form_item.name]" required>
                            <input v-else :id="form_item.name" class="main_input" type="tel" v-model="form[form_item.name]" required />
                            <!-- <input v-else :id="form_item.name" class="main_input" type="tel" v-mask="'+7(###)###-####'" v-model="form[form_item.name]" required /> -->
                        </div>
                        <div v-else>
                            <textarea :id="form_item.name" type="text" class="main_input" v-model="form[form_item.name]" cols="30" rows="5" required v-autogrow></textarea>
                        </div>
                    </div>
                    <button class="button button_default">Отправить</button>
                </form>
                <YouTube v-if="item && item.youtube" :item="item.youtube" />
                <VkFrame v-if="item && item.vk_video && item.vk_video.url" :item="item.vk_video"></VkFrame>
            </div>
            <div v-else>
                <PreLoadBox />
            </div>
        </div>
    </div>
</template>
<script>
import VkFrame from '@/components/Plagins/VkFrame/index';
export default {
    name: "Help",
    components: {
        VkFrame
    },
    metaInfo() {
        return {
            title: `Помощь по сайту `,
            meta: [
                {name: 'description', itemprop: 'description', content: `Помощь на портале Окей Город ${this.$myCity.name}` },
                {property: 'og:url', content: this.$config.url + this.$route.fullPath},
                {property: 'og:title', content: `Помощь по сайту `},
                {property: 'og:description', content: `Помощь на портале Окей Город ${this.$myCity.name}`},
                {property: 'og:image', content:'https://okaygorod.com/images/logoHD.png'},
                {property: 'og:type', content: 'website' }
            ]
        }
    },
    data() {
        return {
            Preloader: null,
            item: null,
            data: [{
                    name: 'name',
                    label: 'Ваше имя',
                    input: true
                },
                {
                    name: 'telephone',
                    label: 'Телефон',
                    input: true
                },
                {
                    name: 'email',
                    label: 'E-mail',
                    input: true
                },
                {
                    name: 'text',
                    label: 'Задайте вопрос',
                    input: false
                },
            ],
            form: {
                name: null,
                telephone: null,
                email: null,
                text: null,
            },
        }
    },
    methods: {
        api() {
            let url = this.$config.api_url + this.$route.params.city + '/Help.get';
            this.$http.get(url).then((response) => {
                this.item = response.data.response;
            }).finally(() => {
                this.Preloader = 'finish';
            })
        },
        send() {
            this.Preloader = null;
            let url = this.$config.api_url + this.$route.params.city + '/Help.form';
            this.$http.post(url, this.form).then(() => {
                this.$notify({
                    group: 'error',
                    type: 'success',
                    text: 'Ваше обращение успешно отправлено',
                    title: 'Поздравляем!',
                    duration: 10000,
                });
                this.formReset();
            }).finally(() => {
                this.Preloader = 'finish';
            });
        },
        formReset() {
            this.form.name = null;
            this.form.telephone = null;
            this.form.email = null;
            this.form.text = null;
        },
    },
    mounted() {
        this.$scrollToTop();
        this.api();
    }

}
</script>
<style scoped>
.help_user_title {
    margin-bottom: 20px;
}

.button {
    margin-bottom: 20px;
}
</style>